import React, { useState, useEffect, useRef } from 'react';
import Spinner from '../Spinner';
import wallet_white from '../../assets/images/wallet_white.png';
import QRCode from 'react-qr-code';
import { MdOutlineContentCopy } from "react-icons/md";


function Modal({ isOpen, onClose, qrCodeDataIssuance, userPin, qrCodeDataVerification, type, options }) {
  const [loading, setLoading] = useState(false);
  const [urlIssuance, setUrlIssuance] = useState(null);
  const [urlVerification, setUrlVerification] = useState(null);

  const REACT_APP_WALLET_URL = process.env.REACT_APP_WALLET_URL;
  const modalRef = useRef(null);
  const [activeTab, setActiveTab] = useState(null);

  const [modalTitle, setModalTitle] = useState(null);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    // Create a temporary textarea to copy the text to the clipboard
    const tempTextarea = document.createElement('textarea');
    tempTextarea.value = userPin;
    document.body.appendChild(tempTextarea);
    tempTextarea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextarea);

    // Set isCopied to true to show a message or style change
    setIsCopied(true);
  };
  useEffect(() => {
    if (options === 'both') {
      setActiveTab('issuance');
      setModalTitle(`${type}`);
    } else {
      setActiveTab('verification');
      setModalTitle('Multi-Selection');
    }
  }, [options]);

  const getQRImg = async (qrCodeDataIssuance, qrCodeDataVerification) => {
    try {
      setLoading(true);
      setUrlIssuance(`${qrCodeDataIssuance}`);
      setUrlVerification(`${qrCodeDataVerification}`);

      setTimeout(() => setLoading(false), 1100);
    } catch (err) {
      console.log("Error = ", err);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (isOpen) {
      getQRImg(qrCodeDataIssuance, qrCodeDataVerification);
    }
  }, [isOpen, onClose]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && isOpen) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) {
    return null;
  }

  const handleOpenWithOurWallet = () => {
    console.log(urlIssuance, urlVerification);
    if (urlIssuance || urlVerification) {
      if (activeTab === 'issuance') {
        const modifiedUrl = urlIssuance.startsWith('openid-credential-offer://') ?
          urlIssuance.replace('openid-credential-offer://', REACT_APP_WALLET_URL + '/cb') :
          urlIssuance.replace('openid://cb', REACT_APP_WALLET_URL + '/cb');

        console.log('->', modifiedUrl);
        window.location.href = modifiedUrl;
      } else {
        const modifiedUrl = urlVerification.startsWith('openid-credential-offer://') ?
          urlVerification.replace('openid-credential-offer://', REACT_APP_WALLET_URL + '/cb') :
          urlVerification.replace('openid://cb', REACT_APP_WALLET_URL + '/cb');
        console.log(modifiedUrl);
        window.location.href = modifiedUrl;
      }
    }
  };

  const handleOpenWithAnyNativeWallet = () => {

    if (urlIssuance && urlVerification) {
      if (activeTab === 'issuance') {
        window.location.href = urlIssuance;

      } else {
        window.location.href = urlVerification;

      }
    }

  };

  const switchToTab = (tabName) => {
    setLoading(true);
    setTimeout(() => setLoading(false), 1100);
    setActiveTab(tabName);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 m-2">
      <div className="modal fixed inset-0 bg-black opacity-50 z-40"></div>
      <div className="modal-content bg-white md:w-1/3 h-auto rounded-lg shadow-lg relative z-50" ref={modalRef}>
        <div className="modal-header block items-center justify-center pt-4 px-4 bg-gray-600 text-white rounded-t-lg">
          <span className="modal-title text-lg font-bold">{modalTitle}</span>
          <span className="close text-white text-2xl cursor-pointer float-right mb-4" onClick={onClose}>
            &times;
          </span>
          <div>
            <hr className="border-1 border-gray-300 w-4/5 inline-block mb-1" />

          </div>
          <div className="tabs">
            {options === 'both' && (
              <button
                className={`tab-button px-4 py-2 mx-1 rounded-t border-t border-x ${activeTab === 'issuance' ? 'active bg-white text-gray-800' : ''}`}
                onClick={() => switchToTab('issuance')}
              >
                Issuance
              </button>
            )}
            <button
              className={`tab-button px-4 py-2 mx-1 rounded-t border-t border-x ${activeTab === 'verification' ? 'active bg-white text-gray-800' : ''}`}
              onClick={() => switchToTab('verification')}
            >
              Verification
            </button>
          </div>
        </div>
        <div className="modal-body max-h-[80vh] overflow-y-auto grid items-center justify-center clear-both p-4">

          {activeTab === 'issuance' ? (
            <>
              <p className="mb-2 text-md font-semibold text-slate-800 py-2">Scan the QR through your <b>wwWallet</b> to <strong>get</strong> your {type}</p>
              {loading && <Spinner />}
              {!loading && urlIssuance && (
                <>
                  {userPin && (
                    <>
                      <p className='w-fit flex border rounded-md border-gray-400 py-1 px-2 m-auto'>
                        <span className='mr-2'>
                          PIN:
                        </span>
                        {userPin}
                        <button className='ml-2' onClick={handleCopyClick}>
                          <MdOutlineContentCopy size={20} />
                        </button>
                      </p>
                      {isCopied && <p className='text-sm italic'>Copied to clipboard!</p>}

                    </>
                  )}
                  <div className="mx-auto mt-4 mb-8">
                    <QRCode value={urlIssuance} className='w-full' size={200} />
                    <div className="text-center my-2">
                      <hr className="border-1 border-gray-300 w-2/5 inline-block mb-1" />
                      <span className="text-gray-600 text-sm font-semibold px-2">OR</span>
                      <hr className="border-1 border-gray-300 w-2/5 inline-block mb-1" />
                    </div>
                    <div className="flex flex-col justify-center items-center">
                      <button
                        className="bg-orange-600 hover:bg-orange-700 text-sm text-white font-bold py-2 px-4 mx-2 my-2 rounded w-56 h-12"
                        onClick={handleOpenWithOurWallet}
                      >
                        <div className="flex items-center">
                          <img src={wallet_white} alt="wwWallet Icon" className="w-auto h-8 mr-2" /> {/* Adjust the image URL and size */}
                          Open with wwWallet
                        </div>
                      </button>
                      <button
                        className="bg-blue-400 hover:bg-blue-500 text-sm text-white font-bold py-2 px-4 mx-2 my-2 rounded w-56 h-12"
                        onClick={handleOpenWithAnyNativeWallet}
                      >
                        Open with a Native Wallet
                      </button>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            // Content for the "Verification" tab
            <>
              <p className="mb-2 text-md font-semibold text-slate-800 py-2">Scan the QR through your <b>wwWallet</b> to <strong>present</strong> your {type}</p>
              <div className="mx-auto mt-4 mb-8">
                {loading && <Spinner />}
                {!loading && urlVerification && (
                  <>
                    <QRCode value={urlVerification} className='w-full' size={200} />
                    <div className="text-center my-2">
                      <hr className="border-1 border-gray-300 w-2/5 inline-block mb-1" />
                      <span className="text-gray-600 text-sm font-semibold px-2">OR</span>
                      <hr className="border-1 border-gray-300 w-2/5 inline-block mb-1" />
                    </div>
                    <div className="flex flex-col justify-center items-center">
                      <button
                        className="bg-orange-600 hover:bg-orange-700 text-sm text-white font-bold py-2 px-4 mx-2 my-2 rounded w-56 h-12"
                        onClick={handleOpenWithOurWallet}
                      >
                        <div className="flex items-center">
                          <img src={wallet_white} alt="wwWallet Icon" className="w-auto h-8 mr-2" /> {/* Adjust the image URL and size */}
                          Open with wwWallet
                        </div>
                      </button>
                      <button
                        className="bg-blue-400 hover:bg-blue-500 text-sm text-white font-bold py-2 px-4 mx-2 my-2 rounded w-56 h-12"
                        onClick={handleOpenWithAnyNativeWallet}
                      >
                        Open with a Native Wallet
                      </button>
                    </div>
                  </>
                )}
              </div>
            </>
          )}

        </div>
      </div>
    </div>
  );

}

export default Modal;