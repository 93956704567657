import React from 'react';

function Footer() {
  return (
    <footer className="border-t mx-10 text-white py-4 mt-auto">
      <div className="container text-sm mx-auto text-center">
        <p className='text-gray-800'>
          Powered by <a href="https://github.com/wwWallet" target='_black' className="text-orange-500">wwWallet</a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
