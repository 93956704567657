import React, { useState, useEffect, useRef } from 'react';
import Spinner from '../Spinner';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function PresentModal({ isOpen, onClose, presentationData, presentationImages }) {
  const [loading, setLoading] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false); 
      }, 1100);

      return () => {
        clearTimeout(timer); 
      };
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  if (!presentationData) {
    return "nothing"; 
  }

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 m-2">
      <div className="modal fixed inset-0 bg-black opacity-50 z-40"></div>
      <div className="modal-content bg-white w-full md:w-1/3 h-auto rounded-lg shadow-lg relative z-50 " ref={modalRef}>
        <div className="modal-header block items-center justify-center py-4 px-4 bg-gray-600 text-white rounded-t-lg">
          <span className="modal-title text-lg font-bold">Success Verification</span>
          <span className="close text-white text-2xl cursor-pointer float-right mb-4" onClick={onClose}>
            &times;
          </span>
        </div>
        <div className="modal-body max-h-[80vh] overflow-y-auto items-center justify-center clear-both p-10">
          <div className="mx-auto">
          {loading && <Spinner />}
          </div>
          {!loading && (
          <>
            <Slider {...sliderSettings} className='mb-8'>
              {presentationImages.map((imageUrl, index) => (
                <div key={index} className='px-10'>
                  <img src={imageUrl} alt={`Slide ${index + 1}`} className="w-full rounded-xl" />
                </div>
              ))}
            </Slider>
            <p className='mt-2 mb-2'>Presentation Details</p>
            <textarea
              rows="10"
              readOnly
              className="w-full border p-2 rounded-xl max-h-[30vh]"
              value={JSON.stringify(presentationData, null, 2)}
            />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PresentModal;
