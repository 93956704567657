import React, { useState, useEffect } from 'react';
import config from '../../config.json';
import Modal from './Modal';
import { CiCreditCard1 } from "react-icons/ci";
import { IoMoonOutline } from "react-icons/io5";
import PresentModal from './PresentModal';
import CredentialCard from './CredentialCard';
import {fetchPresentationStatus, generateCredentialOffer, generatePresentationRequest} from '../../api/Api';
import {processPresentationData, processArray} from '../../functions/Functions';

function Content() {
  const [modalOpen, setModalOpen] = useState(false);
  const [qrCodeDataIssuance, setQRCodeDataIssuance] = useState('');
  const [userPin, setUserPin] = useState('');
  const [qrCodeDataVerification, setQRCodeDataVerification] = useState('');

  const [credentialType, setCredentialType] = useState('');
  const [popupOption, setPopupOption] = useState('');
  const [failedImages, setFailedImages] = useState([]);
  const [selectedCredentials, setSelectedCredentials] = useState([]);
  const [showButton, setShowButton] = useState(false);
  
  const [presentModalOpen, setPresentModalOpen] = useState(false);
  const [presentationData, setPresentationData] = useState(null);
  const [presentationImages, setPresentationImages] = useState(null);
  const [receiveSuccess, setReceiveSuccess] = useState(false);

  const openModal = ({url, user_pin, user_pin_required},verificationData, type, options) => {
    setQRCodeDataIssuance(url);
    if (user_pin_required) {
      setUserPin(user_pin);
    }
    else {
      setUserPin(null);
    }
    setQRCodeDataVerification(verificationData);
    setModalOpen(true);
    setCredentialType(type)
    setPopupOption(options)
  };

  // Check for presentation receive with redirect
  const checkForPresentation = async () => {
    try {
      const { responseStatus, dataStatus, presentation } = await fetchPresentationStatus(window.location.href);

      if (responseStatus === 200) {
        if (dataStatus) { 
          const { credentials, matchingImages } = processPresentationData(presentation);
          setReceiveSuccess(true);
          setPresentationData(credentials);
          setPresentationImages(matchingImages);
          setModalOpen(false);
          setPresentModalOpen(true);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // Check for presentation receive with polling
  const pollReceiveVerifiedCredential = async () => {
    while (qrCodeDataVerification!=='') {
      try {
        const { responseStatus, dataStatus, presentation } = await fetchPresentationStatus(qrCodeDataVerification);

        if (responseStatus === 200) {

          if (dataStatus) { 
            const { credentials, matchingImages } = processPresentationData(presentation);
            setPresentationData(credentials);
            setPresentationImages(matchingImages);
            setPresentModalOpen(true);
            break;
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
  };

  useEffect(() => {
    if (!receiveSuccess) {
      checkForPresentation();
    }
    pollReceiveVerifiedCredential();
  }, [receiveSuccess]);
  

  useEffect(() => {
    pollReceiveVerifiedCredential();
  }, [qrCodeDataVerification]);
  

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleCheckboxChange = (type) => {
    if (selectedCredentials.includes(type)) {
      setSelectedCredentials(selectedCredentials.filter((item) => item !== type));
    } else {
      setSelectedCredentials([...selectedCredentials, type]);
    }

  };
  
  useEffect(() => {
    setShowButton(selectedCredentials.length > 0);
  }, [selectedCredentials]);

  const handleImageLoadError = (index) => {
    setFailedImages((prevFailedImages) => [...prevFailedImages, index]);
  };

  useEffect(() => {
    setFailedImages([]);
  }, []);
  

  // Open single issuance/verification modal
  const getCredentialOffer = async (credentialConfig) => {
    try {
      const { url, user_pin, user_pin_required } = await generateCredentialOffer(credentialConfig);
      const verificationUrl = await generatePresentationRequest(credentialConfig.type);
      openModal({ url, user_pin, user_pin_required },verificationUrl, credentialConfig.type.replace(/([A-Z])/g, ' $1'), 'both');

    }
    catch(err) {
      console.error('Error:', err);
    }
  }

  // Open multi verification modal
  const getMultiPresentatonRequest = async () => {
    
    const allCredentialTypes = config.credentials.map((credential) => credential.type);
    const filteredCredentials = config.credentials.filter((credential) => selectedCredentials.includes(credential.type));
    
    const resultedPresentationDefinitionId = processArray(allCredentialTypes,selectedCredentials);
    const verificationUrl = await generatePresentationRequest(resultedPresentationDefinitionId);

    const state = new URL(verificationUrl).searchParams.get('state');
    localStorage.setItem('verification_state', state)
    var selected_types= filteredCredentials.map(credential => credential.type.replace(/([A-Z])/g, (match, p1) => (credential.type.indexOf(match) === 0 ? p1 : ' ' + p1))).join(', ');
    var lastIndex = selected_types.lastIndexOf(',');
    if (lastIndex !== -1) {
      selected_types = selected_types.substring(0, lastIndex) + ' and' + selected_types.substring(lastIndex + 1);
    }
    openModal("",verificationUrl, selected_types,'onlyVerification')

  };

  return (
    <main className="mx-5 my-8 text-center text-center">
      <div className="text-gray-800 text-2xl sm:text-xl font-bold flex justify-center flex-wrap items-center">
      <span>Pick a VC</span>
        <span className="inline-block mx-1">
          <CiCreditCard1 size={35} />
        </span>
        <span className="inline-block mr-1">... and </span>
        <span>shoot for the moon</span>
        <span className="inline-block mx-1">
          <IoMoonOutline size={23} />
        </span>
      </div>

      <div className="my-10 mx-0 lg:mx-20 flex flex-wrap justify-center">
        {config.credentials.map((credential, index) => (
          !failedImages.includes(index) && (
            <CredentialCard
              key={index}
              credential={credential}
              index={index}
              isSelected={selectedCredentials.includes(credential.type)}
              onImageLoadError={handleImageLoadError}
              onCheckboxChange={handleCheckboxChange}
              onCredentialClick={getCredentialOffer}
            />
          )
        ))}

      </div>
      {showButton ? (
        <button className='bg-orange-500 py-2 px-6 text-white rounded font-semibold' onClick={getMultiPresentatonRequest}>Verify</button>
      ):
      <p>Select multiple credentials to verify within a single request</p>
      }

      {/* Render the Modals component */}
      {modalOpen && !presentModalOpen &&(
        <Modal isOpen={modalOpen} onClose={closeModal} qrCodeDataIssuance={qrCodeDataIssuance} userPin={userPin} qrCodeDataVerification={qrCodeDataVerification} type={credentialType} options={popupOption}/>
      )}
      {presentModalOpen && presentationData && presentationImages && (
        <PresentModal
          isOpen={presentModalOpen}
          onClose={() => setPresentModalOpen(false)}
          presentationData={presentationData}
          presentationImages={presentationImages}
        />
      )}
    </main>
  );
}

export default Content;