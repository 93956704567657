// Functions.js
import config from '../config.json';

//get and proccess the presentation data
export const processPresentationData = (presentation) => {
    const presentationPayload = JSON.parse(atob(presentation.split('.')[1]));
    const credentials = presentationPayload.vp.verifiableCredential.map((vcString) => {
        return JSON.parse(atob(vcString.split('.')[1]));
    }).map((credential) => credential.vc);

    const matchingImages = [];

    if (config.credentials && config.credentials.length > 0) {
        credentials.forEach((credential) => {
        if (credential.type) {
            credential['type'].forEach((type) => {
            config.credentials.forEach((configCredential) => {
                if (configCredential.type === type && configCredential.imageSrc) {
                matchingImages.push(configCredential.imageSrc);
                }
            });
            });
        }
        });
    }

    return { credentials, matchingImages };
};

export function processArray(allCredentialTypes,strings) {
    return allCredentialTypes
      .filter(type => strings.includes(type))
      .map((type, index) => (index === 0 ? type : `With${type}`))
      .join('');
  }