import React from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Content from './components/Content/Content';

function App() {
  return (
    <div className="flex flex-col min-h-screen" style={{ background: 'linear-gradient(to bottom, #e7e7e7, #ffffff)' }}>
      <Header />
      <Content className="flex-grow" />
      <Footer />
    </div>
  );
}

export default App;
