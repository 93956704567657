// Api.js
import axios from 'axios';

const VERIFIER_URL = process.env.REACT_APP_VERIFIER_URL;
const VERIFICATION_CALLBACK_URL = process.env.REACT_APP_VERIFICATION_CALLBACK_URL;

export async function fetchPresentationStatus(presentationRequestURL) {
    try {
      const presentationRequestState = new URL(presentationRequestURL).searchParams.get('state');
      const response = await axios.get(`${VERIFIER_URL}/verifier/success/status?state=${presentationRequestState}`);
      
      return {responseStatus: response.status, dataStatus:response.data.status, presentation:response.data.presentation };
    } catch (error) {
      console.error('Error fetching presentation status:', error);
      return {responseStatus: false , dataStatus: false, presentation: null };
    }
  }
  
  export async function generateCredentialOffer(credentialConfig) {
    try {
      const response = await axios.post(credentialConfig.issuerURL + '/demo/generate-credential-offer', credentialConfig.payload);
      const { url, user_pin, user_pin_required } = response.data;
      return { url, user_pin, user_pin_required };
    } catch (error) {
      console.error('Error generating credential offer:', error);
      throw error;
    }
  }
  
  export async function generatePresentationRequest(presentation_definition_id) {
    try {
      const response = await axios.post(VERIFIER_URL + '/demo/presentation-request', {
        presentation_definition_id: presentation_definition_id,
        callback_url: VERIFICATION_CALLBACK_URL,
      });
  
      const { url } = response.data;
      const state = new URL(url).searchParams.get('state');
      localStorage.setItem('verification_state', state);
      
      return url;
    } catch (error) {
      console.error('Error generating presentation request:', error);
      throw error;
    }
  }
