import React from 'react';
import logo from '../../assets/images/logo.png'; // Import your logo image

function Header() {
  return (
    <header className="border-b mx-10 p-4 text-white">
      <div className="container mx-auto">
        <a href="/" className="block text-center">
          <img src={logo} className="w-80 h-auto mx-auto" alt="logo" />
        </a>
      </div>
    </header>
  );
}

export default Header;
