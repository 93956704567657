import React from 'react';
import logo_icon from '../assets/images/fancy-spinner.gif';

function Spinner() {
    return (
        <div className="flex justify-center items-center p-2 mb-8 mt-4">
            <div className="relative h-52 w-52">
                <div className="absolute inset-0 flex items-center justify-center">
                    <img
                        src={logo_icon}
                        className="object-cover rounded-full border-gradient w-52 h-52"
                        alt="logo_icon"
                    />
                </div>
            </div>
        </div>
    );
}

export default Spinner;
