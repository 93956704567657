// CredentialCard.js
import React from 'react';

function CredentialCard({ credential, index, isSelected, onImageLoadError, onCheckboxChange, onCredentialClick }) {
  return (
    <div className="w-full xl:w-1/3 sm:w-1/2 text-center mb-4">
      <div className="inline-block sm:mr-2 sm:mb-0 mb-2 hover:scale-105 transition-transform duration-300 relative">
        <div>
          <a onClick={() => onCredentialClick(credential)}>
            <div className="w-full h-40 md:h-56 lg:h-56 xl:h-56 cursor-pointer rounded-xl overflow-hidden">
              <img
                src={credential.imageSrc}
                alt={`credential_${index}`}
                className="object-cover w-full h-full"
                onError={() => onImageLoadError(index)}
              />
            </div>
          </a>
          <input
            type="checkbox"
            onChange={() => onCheckboxChange(credential.type)}
            checked={isSelected}
            className='w-6 h-6 mt-4 rounded accent-orange-600'
          />
        </div>
      </div>
    </div>
  );
}

export default CredentialCard;